import React, { useState } from "react";

import { Button, Form, Input, Typography } from "antd";

import Icon from "common/components/icon";
import { useLogin } from "login/hooks";

import "./styles.less";

type LoginFormValues = {
  email: string;
  password: string;
};

const { Text } = Typography;

const LoginForm: React.FC = () => {
  const [form] = Form.useForm<LoginFormValues>();
  const [error, setError] = useState("");
  const { isLoading, submit } = useLogin(setError);

  const onFinish = (values: LoginFormValues) => {
    setError("");
    submit(values?.email, values.password);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      className="form"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Please enter your email" },
          { type: "email", message: "Invalid email" },
        ]}
      >
        <Input size="large" placeholder="Enter your email" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          size="large"
          type="password"
          iconRender={(visible) =>
            visible ? <Icon name="eye-open" /> : <Icon name="eye-close" />
          }
        />
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          size="large"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
      {error && <Text type="danger">{error}</Text>}
    </Form>
  );
};

export default LoginForm;
