import { ModuleConfig } from "app/models/module-config";

import parkingCouponRecords from "./parking-coupon-records/routes";

export const root = "parking-couponns";

const config: ModuleConfig = {
  id: "parking-coupon",
  nav: {
    defaultName: "Parking Coupons",
    route: root,
    icon: { name: "vehicle" },
    children: [
      {
        route: `${root}/${parkingCouponRecords.root}`,
        defaultName: "Parking Coupon",
        folderPath: "parking-coupons/parking-coupon-records",
      },
    ],
  },
};

export default config;
