import React, { useEffect } from "react";

import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";

import { Button } from "antd";
import { ReactQueryDevtools } from "react-query/devtools";

import useUserStore from "common/store/useUserStore";
import LoginView from "login";
import refresh from "login/services/refresh";
import Logout from "logout";

import "./App.less";
import AppLayout from "./components/app-layout";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const AppRoutes: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    refresh(window?.location?.pathname, navigate);
  }, []);

  const user = useUserStore((state) => state?.userDetails);
  return (
    <Routes>
      <Route path="login/*" element={<LoginView />} />
      <Route path="logout/*" element={<Logout />} />
      {user?.isAuthenticated && <Route path="a/*" element={<AppLayout />} />}
    </Routes>
  );
};

const SomethingWentWrong: React.FC = () => (
  <div className="h-error-boundary-screen">
    Something went wrong!, Please refresh the screen
    <div>
      <Button onClick={() => window.location.reload()}>
        Click here to refresh
      </Button>
    </div>
  </div>
);

export const router = createBrowserRouter([
  {
    path: "*",
    element: <AppRoutes />,
  },
]);

const App: React.FC = () => (
  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ErrorBoundary>
);

export default App;
