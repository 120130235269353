import { ModuleConfig } from "app/models/module-config";

import mallEmployeesRoutes from "./promotion/routes";

export const root = "promotion";

const config: ModuleConfig = {
  id: "promotion",
  nav: {
    defaultName: "Promotion",
    route: root,
    icon: { name: "users" },
    children: [
      {
        route: `${root}/${mallEmployeesRoutes.root}`,
        defaultName: "Promotion",
        folderPath: "promotion/promotion",
      },
    ],
  },
};

export default config;
