import React from "react";

import { useLocation } from "react-router-dom";

import { Typography } from "antd";

import Logo from "common/components/hi-lite-logo";

import LoginForm from "./components/login-form";

import "./styles.less";

const { Title, Text } = Typography;

const LoginContentWrapper: React.FC = () => {
  const { state } = useLocation();
  return (
    <div className="content-wrapper">
      <div className="content-text">
        <Title level={2}>Login to your account</Title>
        <Text type="secondary">Welcome back! Please enter your details.</Text>
      </div>
      <LoginForm />
      {state?.sessionTimeOut && (
        <div className="session-timeout-info">
          Session timed out. Please login again !
        </div>
      )}
    </div>
  );
};

const LoginView: React.FC = () => (
  <div className="login-container">
    <div className="login-header">
      <div className="content">
        <Logo />
        <Title level={5} className="text-content">
          HiLITE MALL Console
        </Title>
      </div>
    </div>
    <div className="login-body">
      <LoginContentWrapper />
    </div>
  </div>
);

export default LoginView;
