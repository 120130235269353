import { ModuleConfig } from "./module-config";

export enum UserRole {
  MALL_ADMIN = "MALL_ADMIN",
  ADMIN = "SUPER_ADMIN",
}

export type UserDetails = {
  isAuthenticated: boolean;
  role?: UserRole;
  modules: ModuleConfig[];
  userId?: string | null;
};
