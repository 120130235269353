import { ModuleConfig } from "app/models/module-config";

import couponsRoutes from "./coupons/routes";
import issueCouponRoutes from "./issue-coupon/routes";

export const root = "scratch-and-win";

const config: ModuleConfig = {
  id: "scratch-and-win",
  nav: {
    defaultName: "Scratch & Win",
    route: root,
    icon: { name: "trophy" },
    children: [
      {
        route: `${root}/${couponsRoutes.root}`,
        defaultName: "Coupons",
        folderPath: "scratch-and-win/coupons",
      },
      {
        route: `${root}/${issueCouponRoutes.root}`,
        defaultName: "Issue Coupon",
        folderPath: "scratch-and-win/issue-coupon",
      },
    ],
  },
};

export default config;
