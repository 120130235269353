import { ModuleConfig } from "app/models/module-config";

import evnetRecordroutes from "./event-records/routes";

export const root = "events";

const config: ModuleConfig = {
  id: "events",
  nav: {
    defaultName: "Events",
    route: root,
    icon: { name: "loud-speaker" },
    children: [
      {
        route: `${root}/${evnetRecordroutes.root}`,
        defaultName: "Event Records",
        folderPath: "events/event-records",
      },
    ],
  },
};

export default config;
