import { ModuleConfig } from "app/models/module-config";

import helperTextRoutes from "./helper-texts/routes";

export const root = "helper-texts";

const config: ModuleConfig = {
  id: "helper-texts",
  nav: {
    defaultName: "Helper Texts",
    route: root,
    icon: { name: "loud-speaker" },
    children: [
      {
        route: `${root}/${helperTextRoutes.root}`,
        defaultName: "Helper texts",
        folderPath: "helper-texts/helper-texts",
      },
    ],
  },
};

export default config;
