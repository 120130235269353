import { useQuery } from "react-query";

import axiosInstance from "axios-instance";

const GetUserDetailsQuery = "get-user-details";

type UserDetails = {
  id: string;
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
  };
  mall: {
    id: string;
    name: string;
    areaCode: string;
    mallImageUrl: string;
  };
  email: string;
};

const getUser = (id?: string | null) =>
  axiosInstance.get<UserDetails>(`/shopping-malls/users/${id}`);

const useGetUserDetails = (id?: string | null) =>
  useQuery([GetUserDetailsQuery, id], () => getUser(id), {
    enabled: !!id,
  });

export default useGetUserDetails;
