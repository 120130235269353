import AdCampaignConfig from "ad-campaigns/config";
import analyticsConfig from "analytics/config";
import eventsConfig from "events/config";
import helperTextsConfig from "helper-texts/config";
import loyaltyProgramConfig from "loyalty-program/config";
import mallEmployeesConfig from "mall-employees/config";
import ParkingCoupons from "parking-coupons/config";
import promotion from "promotion/config";
import refferalCodesConfig from "referral-codes/config";
import restaurantConfig from "restaurants/config";
import ScratchAndWin from "scratch-and-win/config";
import SlotManager from "slot-manager/config";
import storeConfig from "stores/config";

import { ModuleConfig } from "./models/module-config";

const registeredModules: ModuleConfig[] = [
  analyticsConfig,
  SlotManager,
  AdCampaignConfig,
  storeConfig,
  restaurantConfig,
  eventsConfig,
  loyaltyProgramConfig,
  mallEmployeesConfig,
  promotion,
  refferalCodesConfig,
  ScratchAndWin,
  ParkingCoupons,
  helperTextsConfig,
];
export default registeredModules;
