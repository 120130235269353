import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "antd";

import LocalStorageService from "app/services/local-storage-services";
import useUserStore from "common/store/useUserStore";

import "./styles.less";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const clearUserDetails = useUserStore((store) => store.clearUserDetails);

  useEffect(() => {
    clearUserDetails();
    LocalStorageService.clearToken();
    navigate("/login", {
      replace: true,
      state: { sessionTimeOut: state?.sessionTimeOut },
    });
  }, []);

  return (
    <div className="redirect-to-login">
      <Button
        type="default"
        onClick={() => navigate("/login", { replace: true })}
        size="large"
      >
        Back to login page
      </Button>
    </div>
  );
};

export default Logout;
