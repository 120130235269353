import axios from "axios";

import { router } from "app";
import LocalStorageService from "app/services/local-storage-services";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use((config) => {
  const token = LocalStorageService.getToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      router.navigate("/logout", {
        replace: true,
        state: { sessionTimeOut: true },
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
