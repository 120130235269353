import jwtDecode from "jwt-decode";

import { UserRole } from "app/models/user-details";

type DecodedTokenType = {
  sub: string;
  scp: UserRole;
  exp: number;
  iat: number;
};

export default class LocalStorageService {
  static tokenKey = "token";

  static userIdKey = "userId";

  static storeLocalStorageItem = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  static getLocalStorageItem = (key: string) => localStorage.getItem(key);

  // token related Operations
  static storeToken = (token: string) => {
    LocalStorageService.storeLocalStorageItem(
      LocalStorageService.tokenKey,
      token
    );
  };

  static clearToken = () =>
    localStorage.removeItem(LocalStorageService.tokenKey);

  static getToken = () =>
    LocalStorageService.getLocalStorageItem(LocalStorageService.tokenKey);

  static decodeCurrentToken = (): DecodedTokenType | undefined => {
    const currentToken = LocalStorageService.getToken();
    if (!currentToken) return undefined;
    return jwtDecode<DecodedTokenType>(currentToken);
  };

  static checkIfValidToken = () => {
    const token = LocalStorageService.decodeCurrentToken();
    if (!token) return false;
    return new Date().valueOf() < token?.exp * 1000;
  };

  // userId Operations
  static getUserId = () =>
    LocalStorageService.getLocalStorageItem(LocalStorageService.userIdKey);

  static storUserId = (userId: string) => {
    LocalStorageService.storeLocalStorageItem(
      LocalStorageService.userIdKey,
      userId
    );
  };
}
