import { ModuleConfig } from "app/models/module-config";

import mallEmployeesRoutes from "./mall-employees-records/routes";

export const root = "mall-employees";

const config: ModuleConfig = {
  id: "mall-employees",
  nav: {
    defaultName: "Mall Employees",
    route: root,
    icon: { name: "users" },
    children: [
      {
        route: `${root}/${mallEmployeesRoutes.root}`,
        defaultName: "Mall Employees",
        folderPath: "mall-employees/mall-employees-records",
      },
    ],
  },
};

export default config;
