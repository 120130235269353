import { ModuleConfig } from "app/models/module-config";

import referralCodesRoutes from "./referral-codes-records/routes";

export const root = "invite-codes";

const config: ModuleConfig = {
  id: "invite-codes",
  nav: {
    defaultName: "Invite Codes",
    route: root,
    icon: { name: "code" },
    children: [
      {
        route: `${root}/${referralCodesRoutes.root}`,
        defaultName: "Invite Codes",
        folderPath: "referral-codes/referral-codes-records",
      },
    ],
  },
};

export default config;
