import { ModuleConfig } from "app/models/module-config";
import { UserRole } from "app/models/user-details";

import bannerRoutes from "./banners/routes";
import offerCardsRoutes from "./offer-cards/routes";
import posterRoutes from "./posters/routes";
import promotedContentRotes from "./promoted-contents/routes";

export const root = "ad-campaigns";

const config: ModuleConfig = {
  id: "ad-campaigns",
  nav: {
    defaultName: "Ad Campaigns",
    route: root,
    icon: { name: "lightning" },
    children: [
      {
        route: `${root}/${bannerRoutes.root}`,
        defaultName: "Banners",
        folderPath: "ad-campaigns/banners",
      },
      {
        route: `${root}/${posterRoutes.root}`,
        defaultName: "Posters",
        folderPath: "ad-campaigns/posters",
      },
      {
        route: `${root}/${offerCardsRoutes.root}`,
        defaultName: "Offer Cards",
        folderPath: "ad-campaigns/offer-cards",
      },
      {
        route: `${root}/${promotedContentRotes.root}`,
        defaultName: "Promoted Contents",
        folderPath: "ad-campaigns/promoted-contents",
        roleRequired: UserRole.ADMIN,
      },
    ],
  },
};

export default config;
