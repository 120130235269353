import { ModuleConfig } from "app/models/module-config";

import slotManagerRoots from "./slot-manager-form/routes";

export const root = "slot-manager";

const config: ModuleConfig = {
  id: "slot-manager",
  nav: {
    defaultName: "Slot Manager",
    route: root,
    icon: { name: "sand-timer" },
    children: [
      {
        route: `${root}/${slotManagerRoots.root}`,
        defaultName: "Slot Manager",
        folderPath: "slot-manager/slot-manager-form",
      },
    ],
  },
};

export default config;
