import React from "react";

import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as Bin } from "./bin.svg";
import { ReactComponent as CaretDown } from "./caret-down.svg";
import { ReactComponent as CaretUp } from "./caret-up.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as EyeClose } from "./eye-close.svg";
import { ReactComponent as EyeOpen } from "./eye-open.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as Heart } from "./heart.svg";
import { ReactComponent as InfoOctagon } from "./info-octagon.svg";
import { ReactComponent as Lightning } from "./lightning.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as LoudSpeaker } from "./loud-speaker.svg";
import { ReactComponent as Code } from "./passcode.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Right } from "./right.svg";
import { ReactComponent as SandTimer } from "./sand-timer.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as ShoppingBag } from "./shopping-bag.svg";
import { ReactComponent as ThumbsUp } from "./thumbs-up.svg";
import { ReactComponent as Trophy } from "./trophy.svg";
import { ReactComponent as Upload } from "./upload.svg";
import { ReactComponent as Users } from "./users.svg";
import { ReactComponent as Vehicle } from "./vehicle.svg";
import { ReactComponent as View } from "./view.svg";

const icons: {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  bin: Bin,
  "caret-down": CaretDown,
  "caret-up": CaretUp,
  check: Check,
  close: Close,
  "eye-close": EyeClose,
  "eye-open": EyeOpen,
  filter: Filter,
  heart: Heart,
  "info-octagon": InfoOctagon,
  lightning: Lightning,
  logout: Logout,
  "loud-speaker": LoudSpeaker,
  pencil: Pencil,
  plus: Plus,
  right: Right,
  "sand-timer": SandTimer,
  search: Search,
  "shopping-bag": ShoppingBag,
  "thumbs-up": ThumbsUp,
  trophy: Trophy,
  view: View,
  users: Users,
  code: Code,
  vehicle: Vehicle,
  upload: Upload,
};

export default icons;
