import React, { Suspense, useEffect, useState } from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import Loader from "common/components/loader";
import useUserStore from "common/store/useUserStore";

import SideBar from "../side-bar";

import "./styles.less";

const importView = (module: string) =>
  React.lazy(() => import(`../../../${module}/view`));

const useModules = () => {
  const userDetails = useUserStore((state) => state.userDetails);
  const [views, setViews] = useState(
    [] as { path: string; view: React.ReactNode }[]
  );

  useEffect(() => {
    const loadModuleViews = async () => {
      const modules = userDetails?.modules?.map((module) => {
        if (module.nav.children) {
          return module.nav.children
            .filter(
              (child) =>
                !child.roleRequired || child.roleRequired === userDetails.role
            )
            .map((child) => ({
              path: child.route,
              elementPath: child.folderPath,
            }));
        }
        return [];
      });
      const flattenedModules = modules?.reduce((list, module) => {
        if (module?.length) {
          return [...list, ...module];
        }
        return list;
      }, [] as { path: string; elementPath: string }[]);

      const componentPromises = flattenedModules?.map(async (module) => {
        const View = await importView(module.elementPath);
        return { path: module.path, view: <View /> };
      });
      if (componentPromises) Promise.all(componentPromises).then(setViews);
    };
    loadModuleViews();
  }, [userDetails]);
  return views;
};

const SuspenseLoader: React.FC = () => (
  <div className="suspense">
    <Loader />
  </div>
);

const App: React.FC = () => {
  const modules = useModules();
  return (
    <div className="app-layout">
      <SideBar />
      <div className="site-layout">
        <Suspense fallback={<SuspenseLoader />}>
          <Routes>
            {modules.map((module) => (
              <Route
                key={module.path}
                path={`${module.path}/*`}
                element={module.view}
              />
            ))}
            {modules?.length && (
              <Route
                path="*"
                element={<Navigate to={modules?.[0]?.path} replace />}
              />
            )}
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

export default App;
