import create from "zustand";

import { UserDetails } from "app/models/user-details";

interface store {
  userDetails?: UserDetails;
  clearUserDetails: () => void;
  setUserDetails: (details: UserDetails) => void;
}

const useUserStore = create<store>((set) => ({
  userDetails: {
    isAuthenticated: false,
    modules: [],
  },
  setUserDetails: (userDetails: UserDetails) =>
    set((state) => ({ ...state, userDetails })),
  clearUserDetails: () =>
    set((state) => ({ ...state, userDetails: undefined })),
}));

export default useUserStore;
