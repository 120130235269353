import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { UserRole } from "app/models/user-details";
import registeredModules from "app/registered-modules";
import LocalStorageService from "app/services/local-storage-services";
import axiosInstance from "axios-instance";
import useUserStore from "common/store/useUserStore";

export type LoginResponse = {
  userId: string;
  mallId: string;
  accessToken: string;
  accessTokenType: string;
  userRole: UserRole;
};

const login = ({ email, password }: { email: string; password: string }) =>
  axiosInstance.post<LoginResponse>("/shopping-malls/auth/login", {
    email,
    password,
  });

const loginUser = (onError: (v: string) => void) => {
  const navigate = useNavigate();
  const setUserDetails = useUserStore((state) => state.setUserDetails);
  const { mutate, isLoading } = useMutation(login, {
    onError: ({ response }) => {
      onError(response?.data?.detail);
    },
    onSuccess: (data) => {
      LocalStorageService.storeToken(data?.data?.accessToken);
      LocalStorageService.storUserId(data?.data?.userId);
      setUserDetails({
        isAuthenticated: true,
        userId: data?.data?.userId,
        modules: registeredModules?.filter((module) =>
          module.nav.children.some(
            (child) =>
              !child.roleRequired || child.roleRequired === data?.data?.userRole
          )
        ),
        role: data?.data?.userRole,
      });
      navigate("/a");
    },
  });

  const submit = (email: string, password: string) => {
    mutate({ email, password });
  };

  return { isLoading, submit };
};

export default loginUser;
