import { ModuleConfig } from "app/models/module-config";

import analyticsRoot from "./analytics-screen/routes";

const root = "analytics";

const config: ModuleConfig = {
  id: "analytics",
  nav: {
    defaultName: "App KPIs",
    route: root,
    icon: { name: "filter" },
    children: [
      {
        route: `${root}/${analyticsRoot.root}`,
        defaultName: "analytics",
        folderPath: "analytics/analytics-screen",
      },
    ],
  },
};

export default config;
