import { NavigateFunction } from "react-router-dom";

import { UserRole } from "app/models/user-details";
import registeredModules from "app/registered-modules";
import LocalStorageService from "app/services/local-storage-services";
import useUserStore from "common/store/useUserStore";

export type LoginResponse = {
  userId: string;
  mallId: string;
  accessToken: string;
  accessTokenType: string;
  userRole: UserRole;
};

const refresh = (currentUrl: string, navigate: NavigateFunction) => {
  if (LocalStorageService.checkIfValidToken()) {
    const token = LocalStorageService.decodeCurrentToken();
    const userId = LocalStorageService.getUserId();
    if (token) {
      const userRole = token.scp;
      useUserStore.setState({
        userDetails: {
          userId,
          isAuthenticated: true,
          modules: registeredModules?.filter((module) =>
            module.nav.children.some(
              (child) => !child.roleRequired || child.roleRequired === userRole
            )
          ),
          role: userRole,
        },
      });
    }
    navigate(currentUrl !== "/" ? currentUrl : "/a");
  } else {
    navigate("/login");
  }
};

export default refresh;
