import { ModuleConfig } from "app/models/module-config";

import analyticsRoutes from "./analytics/routes";
import pointRuleRoutes from "./point-rules/routes";
import uploadedBillRoutes from "./uploaded-bills/routes";
import voucherRoutes from "./vouchers/routes";

export const root = "loyalty-program";

const config: ModuleConfig = {
  id: "layalty-program",
  nav: {
    defaultName: "Loyalty Program",
    route: root,
    icon: { name: "heart" },
    children: [
      {
        route: `${root}/${voucherRoutes.root}`,
        defaultName: "Vouchers",
        folderPath: "loyalty-program/vouchers",
      },
      {
        route: `${root}/${uploadedBillRoutes.root}`,
        defaultName: "Uploaded Bills",
        folderPath: "loyalty-program/uploaded-bills",
      },
      {
        route: `${root}/${pointRuleRoutes.root}`,
        defaultName: "Point Rules",
        folderPath: "loyalty-program/point-rules",
      },
      {
        route: `${root}/${analyticsRoutes.root}`,
        defaultName: "Analytics",
        folderPath: "loyalty-program/analytics",
      },
    ],
  },
};

export default config;
